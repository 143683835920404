const general = {
    defaultRedirect: 'https://console.au10tixservices.com'
}

const envDependent = {
    local: {
        defaultApi: 'https://api.au10tixservicesdev.com/ums/redirect',
        oidc: {
            clientId: '0oap0ht5auBSaCDz9357',
            issuer: 'https://login.au10tix.com/oauth2/default',
            redirectUri: 'http://localhost:3000/code/callback',
            logoutUri: 'http://localhost:3000',
        }
    },
    development: {
        defaultApi: 'https://api.au10tixservicesdev.com/ums/redirect',
        oidc: {
            clientId: '0oap0ht5auBSaCDz9357',
            issuer: 'https://login.au10tix.com/oauth2/default',
            redirectUri: 'https://consoleredirect.au10tixservicesdev.com/code/callback',
            logoutUri: 'https://consoleredirect.au10tixservicesdev.com',
        }
    },
    test:{
        defaultApi: 'https://api-weu.au10tixservicesqa.com/ums/redirect',
        oidc: {
            clientId: '0oap0ht5auBSaCDz9357',
            issuer: 'https://login.au10tix.com/oauth2/default',
            redirectUri: 'https://consoleredirect.au10tixservicesqa.com/code/callback',
            logoutUri: 'https://consoleredirect.au10tixservicesqa.com',
        }
    },
    staging: {
        defaultApi: 'https://eus-api.au10tixservicesstaging.com/ums/redirect',
        oidc: {
            clientId: '0oap0ht5auBSaCDz9357',
            issuer: 'https://login.au10tix.com/oauth2/default',
            redirectUri: 'https://consoleredirect.au10tixservicesstaging.com/code/callback',
            logoutUri: 'http://consoleredirect.au10tixservicesstaging.com',
        }
    },
    production: {
        defaultApi: 'https://eus-api.au10tixservices.com/ums/redirect',
        oidc: {
            clientId: '0oap0ht5auBSaCDz9357',
            issuer: 'https://login.au10tix.com/oauth2/default',
            redirectUri: 'https://consoleredirect.au10tixservices.com/code/callback',
            logoutUri: 'https://consoleredirect.au10tixservices.com',
        }
    }
}

let config = envDependent.local;

if (window.location.hostname.includes('dev')) {
	console.log(`identified dev configuration`);
	config = envDependent.development;
} else if (window.location.hostname.includes('qa') || window.location.hostname.includes('test')) {
	console.log(`identified qa configuration`);
	config = envDependent.test;
} else if (window.location.hostname.includes('staging') || window.location.hostname.includes('stg')) {
	console.log(`identified staging configuration`);
	config = envDependent.staging;
} else if (window.location.hostname.split('.')[1] === 'au10tixservices') {
	console.log(`identified production configuration`);
	config = envDependent.production;
}

export default { ...config, ...general };