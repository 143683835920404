import React from 'react';
import ReactDOM from 'react-dom';
import RedirectPage from './components/RedirectPage/RedirectPage';

ReactDOM.render(
  <React.StrictMode>
    <RedirectPage/>
  </React.StrictMode>,
  document.getElementById('root')
);
