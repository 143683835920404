import React from 'react';
import useAuth from '../../services/auth/useAuth';
import useRedirect from '../../services/redirect/useRedirect';
import history from '../../services/auth/history';
import './RedirectPage.css';

const RedirectPage  = () => {
    const auth = useAuth(history);

    if(auth && auth?.accessToken){
        useRedirect(auth.accessToken);
    }

    return (
        <div id="load" className="load">
            <div className="loader"></div>
        </div>
    )
};

export default RedirectPage;
