import { useEffect, useRef, useState } from 'react';
import { OktaConfig, OktaRequest } from '@au10tix/okta-auth';
import useRedirect from '../redirect/useRedirect';
import config from '../../config/config';

const useAuth = history => {
	const [oktaState, setOktaState] = useState({});
	const [clientOrganization, setClientOrganization] = useState(null);
	const [organizationsData, setOrganizationsData] = useState(null);

	const onProcessSuccess = async okta => {
		setOktaState(okta);
		const redirectTo = localStorage.getItem('redirectBack') || '/';
		const url = new URL(redirectTo);
		history.replace(url.pathname + url.search + url.hash);
	};
	const onProcessSuccessRef = useRef(onProcessSuccess);

	useEffect(() => {
		console.log(config.oidc)
		const oktaConfig = new OktaConfig(config.oidc);
		console.log(oktaConfig)
		const oktaRequest = new OktaRequest(oktaConfig);
		oktaRequest.onProcessSuccess = onProcessSuccessRef.current.bind(oktaRequest);
		oktaRequest.authenticate();
	}, []);

	const auth = {
		...oktaState,
		clientOrganization,
		organizationsData,
		setClientOrganization,
	};

	return auth;
};

export default useAuth;
