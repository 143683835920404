import axios from "axios";
import config from '../../config/config';

const useRedirect = async (accessToken) => {
    try{
        const requestConfig = {
            headers: { Authorization: `Bearer ${accessToken}` }
        };

        axios.get(`${config.defaultApi}/v1/getUrl`, requestConfig).then(res => {
            if(res.data.url){
                window.location.href = res.data.url;
            } else {
                throw new Error(`Url is undefined`);
            }
        }).catch(err => (
            console.error(`Error getting redirect url: ${err}`),
            window.location.href = config.defaultRedirect
        ));
    } catch (e) {
        console.error(`Error occurred during redirect, redirecting to default. Details: ${e}`)
        window.location.href = config.defaultRedirect;
    }
};

export default useRedirect;